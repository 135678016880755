(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mars3d-cesium"), require("@turf/turf"));
	else if(typeof define === 'function' && define.amd)
		define("CesiumVectorTile", ["mars3d-cesium", "@turf/turf"], factory);
	else if(typeof exports === 'object')
		exports["CesiumVectorTile"] = factory(require("mars3d-cesium"), require("@turf/turf"));
	else
		root["CesiumVectorTile"] = factory(root["Cesium"], root["turf"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 